<template>
    <div id="MessageText">
        <span id="text">
            <slot>

            </slot>
        </span>
    </div>
</template>

<script>

    export default {
        name: "MessageText",
        components: {

        }
    }

</script>

<style scoped>
    div#MessageText {
        padding: 20px;

        width: 45vw;

        border: 2px solid var(--black);
        border-radius: 12px;
        margin-bottom: 10vw;
        margin-left: 18vw;
    }

    span#text {
        color: var(--black);

        letter-spacing: 1px;

        font-family: "Josefin Sans";
        font-size: 5.5vw;
    }

    @media screen and (max-width: 822px) {
        div#MessageText {
            margin-left: 0;
            margin-bottom: 15vw;

            width: 70vw;
        }

        span#text {
            font-size: 8vw;
        }
    }

    @media screen and (max-width: 498px) {
        div#MessageText {
            margin-top:40vw;
            width: 80vw;
        }
    }

</style>