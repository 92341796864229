<template>
    <div id="SectionContact">
        <header id="headerCards">
            <span id="title">
                CONTACT US
            </span>
            <button id="Button" v-on:click="submit()" v-show="!toggleButton" >
                <span id="textButton">
                    Send Your Message
                </span>
                    <span id="status">{{status}}</span>
            </button>
        
        </header>
        <img
            id="logo"
            src="../../../assets/AC-logo.png"
            alt="logo AC image"
            v-show="!toggleButton" 
        />
       
        <form id ="contactForm" @submit.prevent="submit" @reset="onReset">
            <div class="wrap-input2 validate-input" data-validate="Name is required">
                <input v-model="name" id="name" class="input2" type="text" v-on:input="onInput()"  required  />
                <span class="focus-input2" data-placeholder="NAME"></span>
            </div>
            <div  class="wrap-input2 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                <input class="input2" id="email" type="text" v-model="email" v-on:input="onInput()"  />
                <span class="focus-input2" data-placeholder="EMAIL"></span>
            </div>
            <div class="wrap-input2 validate-input" data-validate="Message is required">
                <textarea v-model="message" id="message" class="input2" v-on:input="onInput()" ></textarea>
                <span class="focus-input2" data-placeholder="MESSAGE"></span>
            </div>
            <button id="Button" v-on:click="submit()" v-show="toggleButton" >
                <span id="textButton">
                    Send Your Message
                </span>
            </button>
  </form>

    </div>
</template>

<script>

    export default {
        name: "SectionContact",
        components: {
        },
        data() {
             return {
                 name: "",
                 email: "",
                 message: "",
                 toggleButton: false,
                 mediaQuerieList: matchMedia("(max-width: 616px)"),
                 token:"",
                 chatId:"",
                 status:""
            };
        },
        computed: {
         
         formValid() {

            var isValid =true;

            if(this.name.length > 0 )
                this.hideValidate("name")
                else
                 {   this.showValidate("name")
                       isValid=false
                 }

                    if(this.email.length > 0 )
                    { 
                        if(/(.+)@(.+){2,}\.(.+){2,}/.test(this.email))
                            this.hideValidate("email")
                        else
                        {this.showValidate("email")
                        isValid=false}
                    }
                     else
                     {
                        this.showValidate("email") 
                        isValid=false
                    }

                         if(this.message.length > 0 )
                            this.hideValidate("message")
                            else
                          {  this.showValidate("message")
                          isValid=false}
                    return isValid;
                }
            }     
        ,
        methods: {

            showValidate(input)
            {
                var thisAlert=document.getElementById(input).parentElement;
                thisAlert.classList.add('alert-validate');
            },

            hideValidate(input){
                var thisAlert=document.getElementById(input).parentElement;
                thisAlert.classList.remove('alert-validate');
            },

            onInput()
            {
               var controls =  document.querySelectorAll(".input2")
               if(controls)
               controls.forEach(control => {
                if(control.value.toString()!="")
                    { 
                         control.classList.add("has-val"); 
                        this.hideValidate(control.id);
                    }else
                        control.classList.remove("has-val"); 
                    });
            return true;
            },

            onReset() {
                this.name = "";
                this.email = "";
                this.message = "";
                this.onInput()
            },
            submit() {
        

                if (!this.formValid) {
                return; }

            
            
            //JSON.parse(localStorage.getItem("messages"));
             var date = new Date().toUTCString();
              //  localStorage.setItem("messages", JSON.stringify(messages));
              let messageJson = `CyberiaMusic\n${date}\nК вам поступил запрос от\n\nИмя: ${this.name}\nЕмайл: ${this.email}\nСообщение:\n${this.message}\n\nСпасибо пока :)`;
          

              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" }
                };
                fetch(`https://api.telegram.org/bot${this.token}/sendMessage?` +
                 new URLSearchParams(  {
                    chat_id: this.chatId,
                    text: messageJson,
                    parse_mode: 'html'
                }), requestOptions).
                then(response => response.json())
                .then(data => {
                    (this.postId = data.id)
                    this.onReset();
                    this.status = 'Your message was send!'
                   setTimeout(() => this.status = '', 1500);
                });
            },
            toggleButtonResponsive(mediaQuerieList) {
                if(mediaQuerieList.matches) {
                    this.toggleButton = true;
                } else {
                    this.toggleButton = false;
                }
            }
        },
        mounted() {
            this.toggleButtonResponsive(this.mediaQuerieList);
            this.mediaQuerieList.addEventListener('change', this.toggleButtonResponsive);
            this.token = window.config.token;
            this.chatId = window.config.chatId;
        }
    }

</script>

<style scoped>
    div#SectionContact {
        margin-top: 3rem;
        margin-bottom:  10rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap:  wrap ;
        vertical-align: middle;
        padding-top: 100px;
        margin-top: -100px;
    }
    
    div#SectionContact,
    header#headerCards
    {
        width: 100%;

        display: flex;
        align-items: flex-end;
    }
   img#logo {
    max-width: 20%;
    max-height: 20%;
    align-self: flex-start;
    margin-top: 1rem;
  
}

form#contactForm
{
    margin-left: 0rem;
    display: flex;
  flex-direction: column;
    width: 75%;
    justify-content: flex-start;
 
}

input {
    outline: none;
    border: none;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5rem;

}

textarea {
    outline: none;
    border: none;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5rem;
}

textarea:focus,
input:focus {
    border-color: transparent !important
}

input:focus::-webkit-input-placeholder {
    color: transparent
}

input:focus:-moz-placeholder {
    color: transparent
}

input:focus::-moz-placeholder {
    color: transparent
}

input:focus:-ms-input-placeholder {
    color: transparent
}

textarea:focus::-webkit-input-placeholder {
    color: transparent
}

textarea:focus:-moz-placeholder {
    color: transparent
}

textarea:focus::-moz-placeholder {
    color: transparent
}

textarea:focus:-ms-input-placeholder {
    color: transparent
}

input::-webkit-input-placeholder {
    color: #999
}

input:-moz-placeholder {
    color: #999
}

input::-moz-placeholder {
    color: #999
}

input:-ms-input-placeholder {
    color: #999
}

textarea::-webkit-input-placeholder {
    color: #999
}

textarea:-moz-placeholder {
    color: #999
}

textarea::-moz-placeholder {
    color: #999
}

textarea:-ms-input-placeholder {
    color: #999
}


.wrap-input2 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5rem;
}

.input2 {
    display: block;
    width: 100%;
   
    font-size: 15px;
    color: #555;
    line-height: 1.2;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5rem;
}

.focus-input2 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
}

.focus-input2::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    background: #ffffff;
    background: -webkit-linear-gradient(45deg, #ffffff, #9489b8);
    background: -o-linear-gradient(45deg, #ffffff, #9489b8);
    background: -moz-linear-gradient(45deg, #ffffff, #9489b8);
    background: linear-gradient(45deg, #ffffff, #9489b8)
}

.focus-input2::after {
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Josefin Sans';
    font-size: 16px;
    font-weight: bold;
    color: #999;
    line-height: 1.2;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

input.input2 {
    height: 45px;
   
}

input.input2+.focus-input2::after {
    top: 16px;
    left: 0
}

textarea.input2 {
    min-height: 115px;
    padding-top: 13px;
    padding-bottom: 13px
}

textarea.input2+.focus-input2::after {
    top: 16px;
    left: 0
}

.input2:focus+.focus-input2::after {
    top: -13px
}

.input2:focus+.focus-input2::before {
    width: 100%
}

.has-val.input2+.focus-input2::after {
    top: -13px
}

.has-val.input2+.focus-input2::before {
    width: 100%
}

 span#status
    {
      
    }

    span#status:hover {
        background-color: var(--black);
    }

    span#status:hover {
        color: var(--white);
    }
    button#Button:hover >  span#status{
        color: var(--white);
    }
    button#Button {
        background-color: var(--white);
        max-width: fit-content;
        border-radius: 10px;
        justify-content: end;
        padding: 10px 40px;
    }

    button#Button,
    span#textButton
    {
        transition: all 200ms;
    }

    button#Button:hover {
        background-color: var(--black);
    }

    button#Button:hover > span#textButton {
        color: var(--white);
    }

    span#textButton {
        font-family: "Josefin Sans";

        text-transform: uppercase;

        font-weight: bold;
    }
 label {
        color: var(--black);

        letter-spacing: 1px;

        font-family: "Josefin Sans";
        font-size: 5.5vw;
    }


    header#headerCards {
        justify-content: space-between;

        margin-bottom: 2rem;
    }

    span#title {
        font-family: "Josefin Sans";
        font-size: 2.5rem;
    }

    ul#cards {
        display: grid;
        grid-template: auto auto / 25% 25% 25% 25%;

        width: 100%;
    }
    @media(max-width:576px) {
    .wrap-contact2 {
        padding: 72px 15px 90px
    }
}

.validate-input {
    position: relative
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px none #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;
    font-family: 'Josefin Sans';
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .4s;
    -o-transition: opacity .4s;
    -moz-transition: opacity .4s;
    transition: opacity .4s;
    font-weight: bold;
}

.alert-validate::after {
    content: "\f12a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1
}


    @media screen and (max-width: 984px) {
        ul#cards {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        
    .alert-validate::before {
        visibility: visible;
        opacity: 1
    }
    }

    @media screen and (max-width: 616px) {
        form#contactForm
        {
            width: 90%;
            justify-content: center;
            flex-direction: column;
        }
        button#Button {
            max-width: none;
            margin-top:2rem;
        }
        div#SectionContact {
            margin: 2rem 0 6.5rem 0;
            justify-content: center;
        }

        header#headerCards {
            justify-content: center;
        }
        div#SectionContact,
         header#headerCards
     {
        width: 100%;

        display: flex;
        align-items: center;
     }
        span#title {
            text-align: center;
        }

        ul#cards {
            margin-bottom: 2rem;
        }
    }

</style>