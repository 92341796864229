<template>
    <section id="sectionFacts">
        <img
            id="imageFacts"
            src="../../../assets/desktop/image_anatol_cyberia.jpg"
            alt="image interactive"
        />
        <article id="articleFacts">
            <div id="messageFacts">
            <h1 id="title">
                CREATION HISTORY
            </h1>
            <p id="paragraph">
                Cyberia Music is an innovative and creative music label based in Germany. Our team of professional DJs and producers focus on creating modern and cutting edge house music. <a href="https://www.anatolcyberia.com/" target="_blank" style="font-weight: bold; text-decoration: none; color: black;">Anatol Cyberia</a>, the founder and head of the company, has a strong background in music industry. His experience, along with the expertise of the rest of the team, make us the perfect choice for all your music needs.
Cyberia Music was founded in 2018 and has since released world-class electronic dance music of its own production around the globe, on all modern streaming platforms.
So if you are a fan of electronic music, you won't want to miss out on what Cyberia Music has to offer. Stay tuned!

            </p>
            </div>
        </article>
    </section>
</template>

<script>
    export default {
        name: "SectionFacts",
        components: {

        },
        data() {
        return {
            showBarText: true,
            mediaQuerieList: matchMedia("(max-width: 830px)"),
        }
        },
        methods: {
            showBr(mediaQuerieList) {
                if(mediaQuerieList.matches) {
                    this.showBarText = false;
                } else {
                    this.showBarText = true;
                }
            }
        },
        mounted() {
            this.showBr(this.mediaQuerieList);

            this.mediaQuerieList.addEventListener('change', this.showBr);
        }
    }
</script>

<style scoped>
    section#sectionFacts {
        display: flex;
        padding-top: 100px;
        margin-top: -50px;
     

        width: 100%;
        height: 30rem;
        margin-bottom: 10rem;
        position: relative;
    }
    
    img#imageFacts {
       width: 35rem;
        border-radius: 12px;
    }

    div#messageFacts {
        background-color: var(--white);

        width: 36rem;
        margin-top:2rem;
        padding: 2rem;
        
        border-radius: 12px 12px 0 0;
        position: absolute;
        right: 25vw;
        bottom: 0;
    }

    h1#title {
        font-family: "Josefin Sans", Helvetica, sans-serif;
        font-size: 3rem;
        font-weight: normal;

        margin-bottom: 2rem;
    }

    p#paragraph {
        color: var(--very-dark-gray);

        font-family: Atala, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 15px;
        text-align: justify;
        line-height: 1.5rem;
    }
    @media screen and (max-width: 1600px) {

        div#messageFacts {
      
            right: 5vw;
        }
    }
    @media screen and (max-width: 830px) {
   

        img#imageFacts {
            width: 100%;

            margin-bottom: 3rem;
        }

        section#sectionFacts {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
            height: auto;
        }

        div#messageFacts {
            position: static;
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }

        h1#title,
        p#paragraph
        {
            text-align: center;
        }
    }

    @media screen and (max-width: 616px) {
        section#sectionFacts {
            margin-top: 5rem;
        }
    }

    @media screen and (max-width: 518px) {
    h1#title {
        font-size: 2rem;
        }

    p#paragraph {
        font-size: 14px;
        text-align: justify;
        }
    }
</style>