<template>
    <footer id="Footer">
        <div id="side-bar-two-sides">
            <img
                id="logo-footer"
                :src="require('../../assets/logo.svg')"
                alt="logo cyberia music"
                style = "display:none"
            /><h1>CYBERIAMUSIC</h1>

            <nav id="nav-bar-footer" style="display:none">
                <ul id="list-items">
                    <li
                        class="item-text-link"
                        v-for="item in navBarFooterLinks"
                        :key="item.id"
                    >
                        <span class="text-link">
                            {{ item.text }}
                        </span>
                    </li>
                </ul>
            </nav>
            <div id="area-social-media">
                <li 
                    class="item-link-social-media"
                    v-for="item in iconsSocialMedia"
                    :key="item.id"
                >
                    <LinkSocialMedia
                        :src="item.src"
                        :alt="item.alt"
                        :href="item.href"
                    />
                </li>
            </div>

            <span id="rights-text">
                © <span id="year"></span> Cyberia Music. All rights reserved.
            </span>

        </div>
    </footer>
</template>

<script>

    import LinkSocialMedia from './LinkSocialMedia/LinkSocialMedia.vue';

    export default {
        name: "Footer",
        components: {
            LinkSocialMedia
        },
        data() {
            return {
                iconsSocialMedia: [
                    {
                        id: 1,
                        src: "icon-itunes.svg",
                        alt: "icon iTunes",
                        href:"https://music.apple.com/us/artist/anatol-cyberia/1132170258"
             
                    },
                    {
                        id: 2,
                        src: "icon-facebook.svg",
                        alt: "icon Facebook",
                        href:"https://www.facebook.com/anatol.cyberia"
                    },
                    {
                        id: 3,
                        src: "icon-twitter.svg",
                        alt: "icon Twitter",
                        href:"https://twitter.com/AnatolCyberia"
                    },
                    {
                        id: 4,
                        src: "icon-soundcloud.svg",
                        alt: "icon Soundcloud",
                        href:"https://soundcloud.com/anatol_cyberia"

                    },
                    {
                        id: 5,
                        src: "icon-youtube.svg",
                        alt: "icon YouTube",
                        href:"https://www.youtube.com/channel/UCl64lkwCmta65BnmLB9KlnA"
                    }
                    ,
                    {
                        id: 6,
                        src: "icon-instagram.svg",
                        alt: "icon Instagram",
                        href:"https://www.instagram.com/anatol_cyberia"
                    }
                ],
                navBarFooterLinks: [
                    {
                        id: 1,
                        text: "About"
                    },
                    {
                        id: 2,
                        text: "Careers"
                    },
                    {
                        id: 3,
                        text: "Events"
                    },
                    {
                        id: 4,
                        text: "Products"
                    },
                    {
                        id: 5,
                        text: "Support"
                    }
                ],
            }
        },
        mounted(){
            document.getElementById("year").innerHTML = new Date().getFullYear();
        }
    }

</script>

<style scoped>

    footer#Footer {
        width: 100%;
        height: 8rem;

        background-color: var(--black);

        justify-content: center;
    }
    h1 {
        color: var(--white);
        margin-top: 1rem;
        
        text-decoration: none;
        
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 700 ;
    }
    div#side-bar-two-sides {
        width: 80%;
        height: inherit;

        display: grid;
        grid-template-areas: 
            "logo areaSocialMedia"
            "navBar rights"
        ;
    }

    nav#nav-bar-footer {
        grid-area: navBar;
        justify-self: flex-start;
    }

    img#logo-footer,
    div#area-social-media,
    nav#nav-bar-footer,
    span#year,
    span#rights-text
    {
        align-self: center;
    }

    img#logo-footer {
        grid-area: logo;
        justify-self: flex-start;
    }

    div#area-social-media {
        width: 14rem;

        grid-area: areaSocialMedia;
        justify-self: flex-end;
    }

    footer#Footer,
    div#area-social-media,
    ul#list-items 
    {
        display: flex;
    }

    div#area-social-media,
    ul#list-items 
    {
        justify-content: space-between;
        align-items: center;
    }

    ul#list-items {
        width: 30vw;
    }

    li {
        list-style-type: none;
    }

    a.link-social-media {
        justify-content: center;
    }

    span.text-link {
        color: var(--white);

        font-family: "Josefin Sans", Helvetica, sans-serif;
        font-weight: bold;
    }
    span#year,
    span#rights-text {
        color: var(--very-dark-gray);

        font-family: "Josefin Sans";
        font-size: 12.5px;
        font-weight: bold;

        grid-area: rights;
        justify-self: flex-end;
    }

    @media screen and (max-width: 1078px) {
        li.item-text-link {
            margin-right: 10px;
        }

        li.item-text-link:last-of-type {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 818px) {
        footer#Footer {
            height: 25rem;
        }

        div#side-bar-two-sides {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }

        ul#list-items {
            flex-direction: column;

            height: 10rem;
        }

        li.item-text-link {
            margin-right: 0;
        }
        span#year,
        span#rights-text {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 450px) {
        span#year,
        span#rights-text {
            font-size: 15px;
        }
    }

</style>