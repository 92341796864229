<template>
    <a href="https://open.spotify.com/artist/0iQAmpkPWrFWcNybqEHVfv?si=Cd1LtzhfTBGJxR_cORzddw" target="_blank" style="text-decoration:none">
    <button id="Button" >
        <span id="textButton">
            <slot>

            </slot>
        </span>
    </button>   
</a> 
</template>

<script>

    export default {
        name: "Button",
        components: {

        }
    }

</script>

<style scoped>
    button#Button {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--white);

        border-radius: 10px;

        padding: 10px 40px;
    }

    button#Button,
    span#textButton
    {
        transition: all 200ms;
    }

    button#Button:hover {
        background-color: var(--black);
    }

    button#Button:hover > span#textButton {
        color: var(--white);
    }

    span#textButton {
        font-family: "Josefin Sans";

        text-transform: uppercase;

        font-weight: bold;
    }

</style>