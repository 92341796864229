<template>
    <header id="Header">
        <img
            id="logo"
            src="../../assets/logo.svg"
            alt="logo image"
            style = "display:none"
        /><a href="#app" v-on:click="setClose2White()" style="text-decoration:none"><h1 id="headerTxt">CYBERIAMUSIC</h1></a>

        <nav 
            id="navBar"
            v-show="toggleMenu === 'desktop'"
        >
            <ul id="listItems">
                <Item
                    v-for="item in itemsNavBar"
                    :key="item.id"
                    :textLink="item.text"
                    :hrefItem="item.href"
                />
            </ul>
        </nav>
        <ButtonMobile 
            v-show="toggleMenu === 'mobile'"
            @click-button="methodMenuMobile"
            :value="valueMenuMobile"
        />
    </header>
</template>

<script>

    import Item from './Item/Item.vue';
    import ButtonMobile from './ButtonMobile/ButtonMobile.vue';

    export default {
        name: "Header",
        components: {
            Item,
            ButtonMobile,
        },
        created () {
            window.addEventListener('scroll', this.scrollFunction);
         },
         unmounted () {
            window.removeEventListener('scroll', this.scrollFunction);
        },
        data() {
            return {
                itemsNavBar: [
                    {
                        id: 1,
                        text: "About",
                        href:  "#sectionFacts"
                    },
                    {
                        id: 2,
                        text: "Music",
                        href:  "#AreaCards"
                    },
                    {
                        id: 3,
                        text: "Contact",
                        href:  "#SectionContact"
                    }
                    ,
                    {
                        id: 4,
                        text: "Social Media",
                        href:  "#Footer"
                    }
                ],
                mediaQuerieList: matchMedia("(max-width: 822px)"),
                toggleMenu: "desktop"
            }
        },
        methods: {
            showButtonMobile(mediaQuerieList) {
                if(mediaQuerieList.matches) {
                    this.toggleMenu = "mobile";
                } else {
                    this.toggleMenu = "desktop";
                }
            },
            scrollFunction() {
                
            if (document.documentElement.scrollTop > 30) {
                document.getElementById("Header").classList.add("top-nav-collapse");
                document.getElementById("headerTxt").classList.add("top-nav-collapse-txt");

                var lines = document.querySelector("line"); 

                if(lines)
                    lines.array.forEach(element => {
                        if(element.style.backgroundColor == "white")
                        element.style.backgroundColor ="black"; 
                    });

                } else if ( document.documentElement.scrollTop < 30 ) {
                document.getElementById("Header").classList.remove("top-nav-collapse");
                document.getElementById("headerTxt").classList.remove("top-nav-collapse-txt");
             
                   var lines1 = document.querySelector("line"); 
                    if(lines1)
                    lines1.array.forEach(element => {
                        if(element.style.backgroundColor == "black")
                        element.style.backgroundColor ="white"; 
                    });


                    }
            },
            setClose2White(){
               
         
            }
        },
        mounted() {

            this.showButtonMobile(this.mediaQuerieList);
            this.mediaQuerieList.addEventListener('change', this.showButtonMobile);
        },
        props: {
            valueMenuMobile: {
                require: true,
                type: Boolean
            },
            methodMenuMobile: {
                require: true,
                type: Function
            }
        }
    }

</script>

<style scoped>
    header#Header {
        width: 100%;
        height: 10rem;
        
        justify-content: space-between;
    }

    img#logo {
        margin-left: 7.5vw;
    }

    h1#headerTxt {
        color: var(--black);
        margin-left: 7.5vw;
        text-decoration: none;
        
        font-family: 'Josefin Sans', sans-serif;
        font-weight: bold ;
        font-size: 3.5rem;
    }

    header#Header,
    nav#navBar,
    ul#listItems
    {
        display: flex;
        align-items: center;
    
    }
    .top-nav-collapse-txt {
    
        font-size: 2.5rem!important;
    }
    .top-nav-collapse {
        background-color: white;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
        position: fixed;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        top: 0;
        width: 100%;
        z-index: 999;
        height: 4rem!important;
	}

    nav#navBar {
        margin-right: 6.5vw;
        transition: all 0.2s;
    }

    ul#listItems {
        width: 20rem;

        justify-content: space-around;
    }

    @media screen and (max-width: 443px) {
        header#Header {
            height: 3.5rem;
            margin: 5vw;
        }
        .top-nav-collapse 
        { 
           
             margin: 0px!important;
             height: 4.3rem!important;
        }
        h1#headerTxt {
            font-size: 2rem;
            margin-top: 0.5rem;
        }
        .top-nav-collapse-txt {
            font-size: 2rem!important;
        }
        img#logo {
            width: 7.5rem;
        }
    }

</style>