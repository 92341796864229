<template>
  <div id="run">
    <Background
 
      :removeBackgroundImage="valueMenuMobile"
    >
      <Header
        :valueMenuMobile="valueMenuMobile"
        :methodMenuMobile="changeMenuMobile"
      />
      
      <MenuMobile
        v-show="valueMenuMobile"
      />
      <div v-show="!valueMenuMobile" id="content">
        <iframe title="Last release" id="LastRelease" style="border-radius:12px" src="" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy">
        </iframe>
      <MessageText
        v-show="!valueMenuMobile"
      >
      INDEPENDENT ELECTRONIC DANCE MUSIC LABEL
      </MessageText>
    </div>
    </Background>
    
    <Main
      v-show="!valueMenuMobile"
    />
    <Footer
      v-show="!valueMenuMobile"
    />
  </div>
</template>

<script>

  import Background from './components/Background/Background.vue';
  import Header from './components/Header/Header.vue';
  import MessageText from './components/MessageText/MessageText.vue';
  import Main from './components/Main/Main.vue';
  import Footer from './components/Footer/Footer.vue';
  import MenuMobile from './components/MenuMobile/MenuMobile.vue';

export default {
    name: 'App',
    components: {
      Background,
      Header,
      MessageText,
      Main,
      Footer,
      MenuMobile
    },
    data() {
      return {
        lastReleaseUrl: "",
        valueMenuMobile: false,
        mediaQuerieList: matchMedia("(max-width: 822px)")
      }
    },
    methods: {
      changeMenuMobile() {
        this.valueMenuMobile = !this.valueMenuMobile;
       
      },
      changeValueResponsive(mediaQuerieList) {
        if(!mediaQuerieList.matches) {
          this.valueMenuMobile = false;
        }
      }
    },
    mounted() {
      this.changeValueResponsive(this.mediaQuerieList);
      document.getElementById("LastRelease").src =  window.config.LastReleseURL;
      this.mediaQuerieList.addEventListener("change", this.changeValueResponsive);
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        if(this.className !="link-social-media")
        document.querySelector(
     
        this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });

        });
      });
    },
    updated() {
      this.mediaQuerieList.addEventListener("change", this.changeValueResponsive);
    }
  }

</script>

<style>

  * {
    margin: 0;
    padding: 0;

    box-sizing: border-box;

    font-family: Arial, Helvetica, sans-serif;
  }

  :root {
    --white: hsl(0, 0%, 100%);
    --black: hsl(0, 0%, 0%);
    --dark-gray: hsl(0, 0%, 55%);
    --very-dark-gray: hsl(0, 0%, 41%);
  }

  @font-face {
    font-family: Alata;
    src: url(./fonts/alata/Alata-Regular.ttf);
  }

  @font-face {
    font-family: "Josefin Sans";
    src: url(./fonts/josefin-sans/static/JosefinSans-Light.ttf);
  }

  div#run {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div#content
  {
    
    display: flex;

  }
  iframe#LastRelease {
        width: 21vw;
        margin-left: 7.5vw;

        border: 0px solid var(--black);
        border-radius: 12px;
      
    }
    @media screen and (max-width: 498px) {
      div#content{
           flex-direction: column;
           justify-content: center;
          
        }
        iframe#LastRelease {
        width: 70%;
        height: fit-content;
        margin-left: 0.5rem;
        display:none;
    }
    }
</style>
